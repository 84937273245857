import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-22059455"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "row no-gutters",
  style: {"height":"100%"}
}
const _hoisted_2 = {
  class: "col-sm-9 col-md-8 right",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  key: 0,
  class: "right-header"
}
const _hoisted_4 = { class: "d-flex align-items-center py-2 px-3" }
const _hoisted_5 = { class: "avatar-sm mr-2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "right-header"
}
const _hoisted_8 = { class: "d-flex align-items-center py-2 px-3" }
const _hoisted_9 = { class: "avatar-sm mr-2" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  class: "chat-container",
  id: "chat-container"
}
const _hoisted_12 = {
  key: 2,
  class: "chat-maker p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatItem = _resolveComponent("ChatItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.chosenPerson)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("i", {
                class: "fa fa-arrow-left mr-3 pointer",
                style: {"font-size":"18px"},
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: _ctx.getPhoto(_ctx.chosenPerson),
                  alt: "",
                  class: "avatar-img rounded-circle"
                }, null, 8, _hoisted_6)
              ]),
              _createElementVNode("b", null, _toDisplayString(_ctx.chosenPerson.name), 1)
            ])
          ]))
        : (_ctx.activeChatGroup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("i", {
                  class: "fa fa-arrow-left mr-3 pointer",
                  style: {"font-size":"18px"},
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
                }),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("img", {
                    src: _ctx.getPhoto(_ctx.getOpponent(_ctx.activeChatGroup)),
                    alt: "",
                    class: "avatar-img rounded-circle"
                  }, null, 8, _hoisted_10)
                ]),
                _createElementVNode("b", null, _toDisplayString(_ctx.getOpponent(_ctx.activeChatGroup).name), 1)
              ])
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chats, (v, k) => {
          return (_openBlock(), _createBlock(_component_ChatItem, {
            chat: v,
            class: _normalizeClass({'mt-1': k === 0})
          }, null, 8, ["chat", "class"]))
        }), 256))
      ]),
      (_ctx.activeChatGroup || _ctx.chosenPerson)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control mr-3",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.chat) = $event)),
              style: {"flex":"1"},
              onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["enter"])),
              placeholder: "Send message..."
            }, null, 544), [
              [_vModelText, _ctx.chat]
            ]),
            _createElementVNode("i", {
              class: "fa fa-paper-plane send mr-2",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)))
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}