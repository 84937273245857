import MasterData from "@/components/master_data/MasterData.vue";
import CreateMataPelajaran from "@/components/master_data/create/CreateMataPelajaran.vue";
import UpdateMataPelajaran from "@/components/master_data/update/UpdateMataPelajaran.vue";
import DetailMataPelajaran from "@/components/master_data/detail/DetailMataPelajaran.vue";
import CreateGuru from "@/components/master_data/create/CreateGuru.vue";
import UpdateGuru from "@/components/master_data/update/UpdateGuru.vue";
import DetailGuru from "@/components/master_data/detail/DetailGuru.vue";
import CreateKelas from "@/components/master_data/create/CreateKelas.vue";
import UpdateKelas from "@/components/master_data/update/UpdateKelas.vue";
import DetailKelas from "@/components/master_data/detail/DetailKelas.vue";
import UpdateSiswa from "@/components/master_data/update/UpdateSiswa.vue";
import DetailSiswa from "@/components/master_data/detail/DetailSiswa.vue";

export default [
  {
    path: '/master-data',
    name: 'master-data',
    component: MasterData,
    meta: {
      title: 'Master Data'
    }
  },
  {
    path: '/master-data/add-mapel',
    name: 'master-data/add-mapel',
    component: CreateMataPelajaran,
    meta: {
      title: 'Create Mata Pelajaran'
    }
  },
  {
    path: '/master-data/edit-mapel/:id',
    name: 'master-data/edit-mapel',
    component: UpdateMataPelajaran,
    meta: {
      title: 'Update Mata Pelajaran'
    }
  },
  {
    path: '/master-data/detail-mapel/:id',
    name: 'master-data/detail-mapel',
    component: DetailMataPelajaran,
    meta: {
      title: 'Detail Mata Pelajaran'
    }
  },
  {
    path: '/master-data/add-guru',
    name: 'master-data/add-guru',
    component: CreateGuru,
    meta: {
      title: 'Create Guru'
    }
  },
  {
    path: '/master-data/edit-guru/:id',
    name: 'master-data/edit-guru',
    component: UpdateGuru,
    meta: {
      title: 'Update Guru'
    }
  },
  {
    path: '/master-data/detail-guru/:id',
    name: 'master-data/detail-guru',
    component: DetailGuru,
    meta: {
      title: 'Detail Guru'
    }
  },
  {
    path: '/master-data/add-kelas',
    name: 'master-data/add-kelas',
    component: CreateKelas,
    meta: {
      title: 'Create Kelas'
    }
  },
  {
    path: '/master-data/edit-kelas/:id',
    name: 'master-data/edit-kelas',
    component: UpdateKelas,
    meta: {
      title: 'Update Kelas'
    }
  },
  {
    path: '/master-data/detail-kelas/:id',
    name: 'master-data/detail-kelas',
    component: DetailKelas,
    meta: {
      title: 'Detail Kelas'
    }
  },
  {
    path: '/master-data/edit-siswa/:id',
    name: 'master-data/edit-siswa',
    component: UpdateSiswa,
    meta: {
      title: 'Update Siswa'
    }
  },
  {
    path: '/master-data/detail-siswa/:id',
    name: 'master-data/detail-siswa',
    component: DetailSiswa,
    meta: {
      title: 'Detail Siswa'
    }
  },
]