import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2340efde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card timeline-item" }
const _hoisted_2 = { class: "card-header d-flex" }
const _hoisted_3 = { style: {"flex":"1"} }
const _hoisted_4 = {
  key: 0,
  class: "badge badge-danger text-white"
}
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "fw-bold mb-0" }
const _hoisted_7 = {
  key: 0,
  class: "ctr"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "d-flex justify-content-center" }
const _hoisted_10 = { class: "d-flex justify-content-center align-items-center mt-2" }
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", _hoisted_3, _toDisplayString(_ctx.toIndoDateNameTime(_ctx.announcement.created_at)), 1),
      (_ctx.dashboard)
        ? (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString('Announcement')))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.announcement.title), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.announcement.message), 1),
      (_ctx.announcement.action_detail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.expanded)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: _ctx.announcement.action_detail
                }, null, 8, _hoisted_8))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("i", {
                onClick: _cache[0] || (_cache[0] = () => _ctx.expanded = !_ctx.expanded),
                class: "toggle"
              }, _toDisplayString(_ctx.expanded ? 'Hide Details' : 'Show Details'), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.announcement.pdf)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "btn btn-success btn-sm mt-2",
              href: _ctx.announcement.pdf,
              target: "_blank"
            }, " Download Attachment ", 8, _hoisted_11))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}